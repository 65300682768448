import React, { useState, useRef, useEffect } from 'react';
import { pack, hierarchy, stratify } from 'd3';
import PropTypes from 'prop-types';
import { StaticQuery, graphql, Link, navigate } from 'gatsby';

import mumSVG from '../img/svgBlob/level4/blob4.svg';

import BlobsSVG1_1 from '../img/svgBlob/level1/1/indexBlobSVG1_1';
import BlobsSVG1_2 from '../img/svgBlob/level1/2/indexBlobSVG1_2';
import BlobsSVG1_3 from '../img/svgBlob/level1/3/indexBlobSVG1_3';
import BlobsSVG1_4 from '../img/svgBlob/level1/4/indexBlobSVG1_4';

import BlobsSVG2_1 from '../img/svgBlob/level2/1/indexBlobSVG2_1';
import BlobsSVG2_2 from '../img/svgBlob/level2/2/indexBlobSVG2_2';
import BlobsSVG2_3 from '../img/svgBlob/level2/3/indexBlobSVG2_3';
import BlobsSVG2_4 from '../img/svgBlob/level2/4/indexBlobSVG2_4';

import BlobsSVG3_1 from '../img/svgBlob/level3/1/indexBlobSVG3_1';
import BlobsSVG3_2 from '../img/svgBlob/level3/2/indexBlobSVG3_2';
import BlobsSVG3_3 from '../img/svgBlob/level3/3/indexBlobSVG3_3';
import BlobsSVG3_4 from '../img/svgBlob/level3/4/indexBlobSVG3_4';

import BlobsSVG4_1 from '../img/svgBlob/level4/1/indexBlobSVG4_1';
import BlobsSVG4_2 from '../img/svgBlob/level4/2/indexBlobSVG4_2';
import BlobsSVG4_3 from '../img/svgBlob/level4/3/indexBlobSVG4_3';
import BlobsSVG4_4 from '../img/svgBlob/level4/4/indexBlobSVG4_4';

import BlobsSVG2 from '../img/svgBlob/level2/indexBlobSVG2';
import BlobsSVG3 from '../img/svgBlob/level3/indexBlobSVG3';
import BlobsSVG4 from '../img/svgBlob/level4/indexBlobSVG4';

function MyCirclePack({ data, width, height, zoomFactor }) {
  const heightCanvas = height;
  const widthCanvas = width;
  const groupRef = useRef();
  const [isSelected, setSelection] = useState(0);
  const prevIsSelected = useRef();
  const prevIsSelectedTwo = useRef();

  const { edges: posts } = data.allMarkdownRemark;
  let allPosts = posts.map((el) => ({
    name: el.node.frontmatter.title.toLowerCase(),
    parent: el.node.frontmatter.responseTo.toLowerCase().replace(/ /g, '-'),
    mediaType: el.node.frontmatter.mediaType,
    url: el.node.fields.slug,
    artist: el.node.frontmatter.nameOfArtists[0].artist,
  }));

  const indexofParent = allPosts.findIndex((p) => p.name == 'community');
  allPosts[indexofParent] = {
    name: 'community',
    parent: '',
    mediaType: 'text',
    url: '/projects/community/',
  };

  const allMediaTypes = allPosts.map((el) => {
    return el.mediaType;
  });
  const uniqueMediaTypes = [...new Set(allMediaTypes)];

  const values1 = Object.values(BlobsSVG1_1);
  const values2 = Object.values(BlobsSVG2);
  const values3 = Object.values(BlobsSVG3_1);
  const values4 = Object.values(BlobsSVG4_1);
  const mediaOther = [values1, values2, values3, values4];

  const valuesImg1 = Object.values(BlobsSVG1_2);
  const valuesImg2 = Object.values(BlobsSVG2);
  const valuesImg3 = Object.values(BlobsSVG3);
  const valuesImg4 = Object.values(BlobsSVG4);
  const image = [valuesImg1, valuesImg2, valuesImg3, valuesImg4];

  const valuesText1 = Object.values(BlobsSVG1_3);
  const valuesText2 = Object.values(BlobsSVG2);
  const valuesText3 = Object.values(BlobsSVG3);
  const valuesText4 = Object.values(BlobsSVG4);
  const text = [valuesText1, valuesText2, valuesText3, valuesText4];

  const valuesAudio1 = Object.values(BlobsSVG1_4);
  const valuesAudio2 = Object.values(BlobsSVG2);
  const valuesAudio3 = Object.values(BlobsSVG3);
  const valuesAudio4 = Object.values(BlobsSVG4);
  const audio = [valuesAudio1, valuesAudio2, valuesAudio3, valuesAudio4];

  function getRandomBlobSVG(i, r, mediaType) {
    if (i === 0) return mumSVG;

    let url;
    let blobArray;
    if (mediaType === uniqueMediaTypes[0]) {
      blobArray = [...image];
    } else if (mediaType === uniqueMediaTypes[1]) {
      blobArray = [...text];
    } else if (mediaType === uniqueMediaTypes[2]) {
      blobArray = [...audio];
    } else {
      blobArray = [...mediaOther];
    }

    switch (r) {
      case uniqueRadius[0]:
        url = blobArray[0][i % blobArray[0].length];
        break;
      case uniqueRadius[1]:
        url = blobArray[1][i % blobArray[1].length];
        break;
      case uniqueRadius[2]:
        url = blobArray[2][i % blobArray[2].length];
        break;
      case uniqueRadius[3]:
        url = blobArray[3][i % blobArray[3].length];
        break;
      default:
        url = blobArray[3][i % blobArray[3].length];
    }
    return url;
  }

  let isMobile = false;
  if (width < 450) isMobile = true;

  function getRandomRotation(i) {
    if (isMobile) {
      return (i * 5) % 360;
    } else {
      return (i * 10) % 360;
    }
  }

  function getRandomPositionX(i, r) {
    const sign = (i % 3) - 1;
    if (isMobile) {
      return r - ((i * 72 * sign) % 30);
    } else {
      return r - ((i * 72 * sign) % 60);
    }
  }

  function getRandomPositionY(i, r) {
    const sign = (i % 2) * 2 - 1;
    if (isMobile) {
      return r + ((i * 32) % 20);
    } else {
      return r + ((i * 32 * sign) % 30);
    }
  }
  // 86, 75,
  function getRandomScale(i) {
    return (i % 5) * 0.1 + 0.9;
  }

  var root = stratify()
    .id(function (d) {
      return d.name;
    })
    .parentId(function (d) {
      return d.parent;
    })(allPosts);

  const dataP = hierarchy(root).sum(() => 1);
  const createPack = pack().size([widthCanvas, heightCanvas]).padding(5);
  const projectPack = createPack(dataP);

  const allRadius = projectPack.descendants().map((el) => {
    return el.r;
  });
  const uniqueRadius = [...new Set(allRadius)];
  uniqueRadius.sort((a, b) => a - b);

  const handleMouseLeave = (url) => {
    if (isMobile) {
    } else {
      setSelection(0);
    }
  };

  let clicks = 0;
  const DELAY = 600;
  let timer = null;

  const handleClickEvent = (event) => {
    clicks++;
    if (clicks === 1) {
      timer = setTimeout(() => {
        handleSingleClick(event.target.attributes[0].nodeValue);
        clicks = 0; //after action performed, reset counter
      }, DELAY);
    } else {
      clearTimeout(timer); //prevent single-click action
      handleDoubleClick(event.target.attributes[0].nodeValue);
      clicks = 0; //after action performed, reset counter
    }
  };

  const handleSingleClick = (url) => {
    if (isMobile) {
    } else {
      navigate(url);
    }
  };

  const handleDoubleClick = (url) => {
    navigate(url);
  };

  useEffect(() => {
    //assign the ref's current value to the count Hook
    if (isSelected !== 0) {
      prevIsSelectedTwo.current = prevIsSelected.current;
      prevIsSelected.current = isSelected;
    }
  }, [isSelected]);

  return (
    <g>
      {projectPack.descendants().map(({ x, y, r, data }, i, arr) => (
        <g key={data.id}>
          <defs>
            <filter id="noiseDisp" colorInterpolationFilters="sRGB">
              <feTurbulence
                baseFrequency={0.005}
                numOctaves="3"
                seed={100}
                type="fractalNoise"
                result="turbulence"
              />
              <feColorMatrix
                in="turbulence"
                type="hueRotate"
                values="0"
                result="turbulenceAnimated"
              >
                <animate
                  attributeName="values"
                  from="0"
                  to="360"
                  dur="6s"
                  repeatCount="indefinite"
                />
              </feColorMatrix>
              <feDisplacementMap
                type="displaced"
                in="SourceGraphic"
                in2="turbulenceAnimated"
                xChannelSelector="R"
                yChannelSelector="B"
                scale={30}
                result="displaced"
              />
              <feGaussianBlur in="displaced" stdDeviation="10" result="blurred" />
              <feMerge>
                <feMergeNode in="blurred" />
                <feMergeNode in="displaced" />
              </feMerge>
            </filter>
          </defs>
          <g className="circleContainer">
            <g>
              <g
                ref={groupRef}
                transform={`translate(${x},${y})`}
                className="blobContainer"
                onMouseOver={() => setSelection(data.data.url)}
                onMouseLeave={() => handleMouseLeave(data.data.url)}
                onTouchStart={() => setSelection(data.data.url)}
              >
                <g
                  style={
                    (isSelected === data.data.url || prevIsSelected.current === data.data.url) &&
                    isMobile === false
                      ? { filter: 'url(#noiseDisp)' }
                      : { filter: 'none' }
                  }
                >
                  {i > 0 && (
                    <image
                      className="imageBlob"
                      x={-getRandomPositionX(i, r)}
                      y={-getRandomPositionY(i, r)}
                      width={r * 2}
                      height={r * 2}
                      href={getRandomBlobSVG(i, r, data.data.mediaType)}
                      transform={
                        'rotate(' + getRandomRotation(i) + '), scale(' + getRandomScale(i) + ')'
                      }
                    />
                  )}

                  {isSelected === data.data.url && i > 0 && (
                    <image
                      url={data.data.url}
                      onClick={handleClickEvent}
                      x={-getRandomPositionX(i, r)}
                      y={-getRandomPositionY(i, r)}
                      width={r * 2}
                      height={r * 2}
                      href={getRandomBlobSVG(i, r, data.data.mediaType)}
                      transform={
                        'rotate(' + getRandomRotation(i) + '), scale(' + getRandomScale(i) + ')'
                      }
                      opacity="0.5"
                    />
                  )}
                </g>
                <g>
                  {i === 0 && (
                    <image
                      className="imageBlob"
                      x={-r * 1.3}
                      y={-r * 1.4}
                      width={r * 2.6}
                      height={r * 2.6}
                      href={getRandomBlobSVG(i, r, data.data.mediaType)}
                      transform={'rotate(15)'}
                    />
                  )}
                  {isSelected === data.data.url && i === 0 && (
                    <image
                      url={data.data.url}
                      onClick={handleClickEvent}
                      x={-r * 1.3}
                      y={-r * 1.4}
                      width={r * 2.6}
                      height={r * 2.6}
                      href={getRandomBlobSVG(i, r, data.data.mediaType)}
                      transform={'rotate(15)'}
                      opacity="0.5"
                    />
                  )}
                </g>
              </g>

              {isSelected === data.data.url && (
                <Link to={data.data.url}>
                  <g className="label" transform={`translate(${x + r / 1.5},${y - r / 3})`}>
                    <foreignObject x={0} y={0} width="200px" height="200px">
                      <p
                        className="textLabel"
                        xmlns="http://www.w3.org/1999/xhtml"
                        style={
                          isMobile === false
                            ? {
                                fontSize: `${0.9 / (zoomFactor / 1.65)}rem`,
                                lineHeight: `${1 / (zoomFactor / 1.4)}rem`,
                              }
                            : {
                                fontSize: `${0.75 / (zoomFactor / 1.65)}rem`,
                                lineHeight: `${1 / zoomFactor}rem`,
                                width: '100px',
                              }
                        }
                      >
                        {arr[i].data.data.name}
                      </p>
                      <p
                        className="textLabel"
                        xmlns="http://www.w3.org/1999/xhtml"
                        style={
                          isMobile === false
                            ? {
                                fontSize: `${0.6 / (zoomFactor / 1.65)}rem`,
                              }
                            : { fontSize: `${0.5 / (zoomFactor / 1.65)}rem` }
                        }
                      >
                        {arr[i].data.data.artist}
                      </p>
                    </foreignObject>
                  </g>
                </Link>
              )}
            </g>
          </g>
        </g>
      ))}
    </g>
  );
}

CirclePack.propTypes = {
  data: PropTypes.shape({
    allMarkdownRemark: PropTypes.shape({
      edges: PropTypes.array,
    }),
  }),
};

export default function CirclePack(props) {
  return (
    <StaticQuery
      query={graphql`
        query dataVizQuery {
          allMarkdownRemark(
            sort: { order: ASC, fields: [frontmatter___title] }
            filter: { frontmatter: { templateKey: { eq: "blog-post" } } }
          ) {
            edges {
              node {
                id
                fields {
                  slug
                }
                frontmatter {
                  title
                  mediaType
                  responseTo
                  nameOfArtists {
                    artist
                  }
                }
              }
            }
          }
        }
      `}
      render={(data) => <MyCirclePack data={data} {...props} />}
    />
  );
}
