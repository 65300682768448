import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { graphql } from 'gatsby';
import Layout from '../components/Layout';
import CirclePack from '../components/CirclePack';
import getWindowDimensions from '../components/WindowDimensions';
import Content, { HTMLContent } from '../components/Content';

export const IndexPageTemplate = ({ title, content, contentComponent }) => {
  const PageContent = contentComponent || Content;
  const [showGuide, setShowGuide] = React.useState(false);
  const toggleGuide = (event) => {
    setShowGuide((current) => !current);
  };
  const windowDimensions = getWindowDimensions();
  const width = windowDimensions.width;
  const height = windowDimensions.height;

  const [dragActive, setDragActive] = useState(0);
  const [k, setK] = useState(2);

  const handleClickZoomIn = () => {
    let zoom = k;
    if (zoom < 2.5) zoom += 0.5;
    setK(zoom);
  };

  const handleClickZoomOut = () => {
    let zoom = k;
    if (zoom > 1) zoom -= 0.5;
    setK(zoom);
  };

  const containerRef = useRef();

  useEffect(() => {
    const svgEl = containerRef.current;

    if (width < 450) {
      svgEl.scrollTop = height / 2;
    } else {
      svgEl.scrollTop = height / 2;
    }
    svgEl.scrollLeft = width / 2.5;
  }, [containerRef.current]);

  useEffect(() => {
    const svgEl = containerRef.current;
    let pos = { top: 0, left: 0, x: 0, y: 0 };

    // Handler for when the cursor is pressed down
    const mouseDownHandler = function (e) {
      // Stores data on the current scroll and mouse position
      pos = {
        // The current scroll
        left: svgEl.scrollLeft,
        top: svgEl.scrollTop,
        // Get the current mouse position
        x: e.clientX,
        y: e.clientY,
      };

      // Change style of cursor and disable selection for better UX
      // svgEl.style.cursor = 'grabbing';
      svgEl.style.userSelect = 'none';

      // Attach events when the cursor is moved or released
      document.addEventListener('mousemove', mouseMoveHandler);
      document.addEventListener('mouseup', mouseUpHandler);
    };

    const touchStartHandler = function (e) {
      // Stores data on the current scroll and mouse position
      pos = {
        // The current scroll
        left: svgEl.scrollLeft,
        top: svgEl.scrollTop,
        // Get the current mouse position
        x: e.touches[0].clientX,
        y: e.touches[0].clientY,
      };

      // Change style of cursor and disable selection for better UX
      // svgEl.style.cursor = 'grabbing';
      svgEl.style.userSelect = 'none';

      // Attach events when the cursor is moved or released
      document.addEventListener('touchmove', touchMoveHandler);
      document.addEventListener('touchend', touchUpHandler);
    };

    // Handler for when the cursor is moving
    // If you don't want to hold down the cursor and have the container to always follow the pointer
    // you will need ONLY this handler
    const mouseMoveHandler = function (e) {
      setDragActive(true);

      // How far the mouse has been moved
      const dx = e.clientX - pos.x;
      const dy = e.clientY - pos.y;

      // Scroll the element
      svgEl.scrollTop = pos.top - dy;
      svgEl.scrollLeft = pos.left - dx;
    };

    // Handler for when the cursor is released
    const mouseUpHandler = function () {
      // Revert previous style changes
      setDragActive(false);
      // svgEl.style.cursor = 'default';
      // svgEl.style.removeProperty('user-select');
      // IMPORTANT! This stops the container to be scrolled once the cursor is pressed
      document.removeEventListener('mousemove', mouseMoveHandler);
    };

    const touchMoveHandler = function (e) {
      setDragActive(true);

      // How far the mouse has been moved
      const dx = e.touches[0].clientX - pos.x;
      const dy = e.touches[0].clientY - pos.y;

      // Scroll the element
      svgEl.scrollTop = pos.top - dy;
      svgEl.scrollLeft = pos.left - dx;
    };

    const touchUpHandler = function () {
      // Revert previous style changes
      setDragActive(false);
      // svgEl.style.cursor = 'default';
      // svgEl.style.removeProperty('user-select');
      // IMPORTANT! This stops the container to be scrolled once the cursor is pressed
      document.removeEventListener('touchmove', mouseMoveHandler);
    };

    window.addEventListener('mousedown', mouseDownHandler);
    window.addEventListener('touchstart', touchStartHandler);
  }, []);

  return (
    <div className="indexPage">
      <div className=" zoomBtnContainer ">
        <button className=" zoomIn " onClick={handleClickZoomIn}>
          +
        </button>
        <button className=" zoomOut " onClick={handleClickZoomOut}>
          -
        </button>
      </div>
      {showGuide && (
        <div className="container">
          <div className="backdrop" onClick={toggleGuide}></div>
          <div className={`overlay ${showGuide ? 'visible' : 'hidden'}`}>
            <div
              className="is-pulled-right closeButton guide-overlay-btn has-text-light is-family-primary"
              data-target="artistInfo"
              tabIndex={0}
              onClick={toggleGuide}
              onKeyPress={toggleGuide}
            >
              X
            </div>
            <br></br>
            <br></br>
            <div className="overlay-content">
              <h1>{title}</h1>
              <PageContent content={content} />
            </div>
          </div>
        </div>
      )}
      <div className="sketch-container" ref={containerRef}>
        {width !== undefined && (
          <svg
            className={`bubbleSvg ${dragActive ? 'disableClick' : ''} ${
              width < 500 ? ' mobile' : ''
            }`}
            width={width * 1.5}
            height={height * 1.75}
            style={{ transform: 'scale(' + k + ')' }}
          >
            <g transform={`translate(${width / 3},${height / 1.95})`}>
              <CirclePack
                className="circlePackClass"
                width={width}
                height={height}
                zoomFactor={k}
              />
            </g>
          </svg>
        )}
      </div>

      <button className=" guideBtn " onClick={toggleGuide}>
        i
      </button>
    </div>
  );
};

IndexPageTemplate.propTypes = {
  title: PropTypes.string,
  contentComponent: PropTypes.func,
};

const IndexPage = ({ data }) => {
  const { markdownRemark: pagedata } = data;

  return (
    <Layout>
      <IndexPageTemplate
        title={pagedata.frontmatter.title}
        contentComponent={HTMLContent}
        content={pagedata.html}
      />
    </Layout>
  );
};

IndexPage.propTypes = {
  data: PropTypes.object.isRequired,
};

export default IndexPage;

export const pageQuery = graphql`
  query IndexPageTemplate {
    markdownRemark(frontmatter: { templateKey: { eq: "index-page" } }) {
      html
      frontmatter {
        title
      }
    }
  }
`;
